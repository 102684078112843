<template>
	<div class="district-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$route.name === 'district-edit' ? $t("titles.editDistrict") : $t("titles.createDistrict")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$route.name === 'district-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="isFetchingRequiredApiData || ready !== true" />
		<template v-else>
			<div class="row pt-4">
				<div class="col-md-4">
					<form-container>
						<div class="part-label col-12">{{$t("titles.districtDetails")}}</div>
						<field-input :label="$t('labels.name')" class="col-md-12" v-model="name" :error="nameError" />
						<field-input :label="$t('labels.homeAddress')" class="col-md-12" v-model="homeAddress" :error="homeAddressError" />
						<field-datetime :label="$t('labels.allowBookingsFrom')" class="col-md-12" v-model="allowBookingsFrom" :error="allowBookingsFromError" />
						<entity-select :label="$t('labels.country')" class="col-md-12" v-model="country" entity="Country" search-property="name" display-property="name" value-property="@id" :error="countryError" />
						<entity-select v-if="hasGlobalSuperAdmin" :label="$t('labels.company')" class="col-md-12" v-model="company" entity="Company" search-property="name" display-property="name" value-property="@id" :error="companyError" :pay-load-params="{type: 1}" />
					</form-container>
				</div>
				<div class="col-md-8">
					<polygon-input class="py-4" v-model="bounds"  :initialLat='12' :initalLng='12'>
						<template slot-scope="{ google, map }">
							<map-polygon v-for='(pathGroup, index) in readonlyBounds'
								:key='index'
								:google="google"
								:map="map"
								:paths="pathGroup"
								:options='{
									strokeColor: "#C0C0C0",
									strokeOpacity: 0.8,
									strokeWeight: 2,
									fillColor: "#C0C0C0",
									fillOpacity: 0.50,
								}'
							/>
						</template>
					</polygon-input>
				</div>
			</div>
			<div v-if="boundsError !== ''" class="error w-mb-20">{{boundsError}}</div>
			<div class="row" v-if="mayUpdateDisabled">
				<div class="col-md-12">
					<form-container>
						<div class="part-label col-12">{{$t("titles.activeInPeriods")}}</div>
						<div v-for="period in ruleset.booking['BOOKING:SLOTS']" class="row">
							<div class="col-3"><field-select :label="$t('terms.allowed')" :value="disabledInPeriods.indexOf(period.start+'-'+period.end) === -1" :options="[{text:$t('terms.yes'),value: true},{text:$t('terms.no'),value: false}]" @input="toggleDisabledInPeriods(period.start+'-'+period.end)" /></div>
							<div class="col-3"><field-input disabled :value="period.start" :label="$t('labels.start')" /></div>
							<div class="col-3"><field-input disabled :value="period.end" :label="$t('labels.end')" /></div>
							<div class="col-3"><field-input disabled :value="period.priceMultiplier" :label="$t('labels.priceMultiplier')" /></div>
						</div>
					</form-container>
				</div>
			</div>
<!--			@deprecated Rules are no longer editable on district level-->
<!--			<entity-booking-rules-form v-if="mayUpdateRuleset" v-model="ruleset.booking" :errors="bookingRuleErrors" :default-values="routeEntity.defaultRuleset" />
			<entity-currency-rules-form v-if="mayUpdateRuleset" v-model="ruleset.currency" :default-values="routeEntity.defaultRuleset" />-->
		</template>
	</div>
</template>

<script>
	
	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState,mapGetters} from "vuex";
	import GoogleMap from "@/components/maps/GoogleMap";
	import MapPolygon from "@/components/maps/MapPolygon";
	import PolygonInput from "@/components/inputs/PolygonInput";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import EntityBookingRulesForm from "@/components/forms/EntityBookingRulesForm";
	import EntityCurrencyRulesForm from "@/components/forms/EntityCurrencyRulesForm";
	import permissions from "@/permissions";
	import isGranted from "@/mixin/isGranted";
	import requiresApiRequest from "@/mixin/requiresApiRequest";
	import rules from "@/rules";
	import {normalizeRuleValue,extractIdFromIri} from "@/helperFunctions";
	import FieldInput from "@/components/inputs/FieldInput";
	import FormContainer from "@/components/container/FormContainer";
	import SmallButton from "@/components/buttons/SmallButton";
	import FieldDatetime from "@/components/inputs/FieldDatetime";
	import entityMap from "@/entityMap";
	import FieldCheckbox from "@/components/inputs/FieldCheckbox";
	import FieldSelect from "@/components/inputs/FieldSelect";
	let i = 0;
	export default {
		name: "AddEditDistrict",
		mixins: [isGranted,requiresApiRequest],
		components: {
			FieldSelect,
			FieldCheckbox,
			FieldDatetime,
			EntityCurrencyRulesForm,
			EntityBookingRulesForm,
			EntitySelect, PolygonInput, GoogleMap, Spinner, StandardButton, RegularInput, FieldInput, FormContainer, SmallButton, MapPolygon},
		data(){
			return {
				ready:true,
				name: '',
				nameError: '',
				homeAddress: '',
				homeAddressError: '',
				company: '',
				companyError: '',
				allowBookingsFrom: '',
				allowBookingsFromError: '',
				responseError: '',
				bounds: [],
				boundsError: '',
				readonlyBounds: [],
				disabledInPeriods: [],
				country: '',
				countryError: '',
				chiefs: [],
				ruleset: {
					currency: {},
					booking: {}
				},
				bookingRuleErrors: {},
				loader: false,
			}
		},
		computed: {
			...mapState('concrete', {
				districts: 'districts',
				countries: 'countries',
				viewingEntity: 'viewingEntity',
			}),
			...mapGetters('concrete',{
				possibleRules: 'possibleRules',
				groupedRules: 'groupedRules',
				normalizedDefaultRuleValues: 'normalizedDefaultRuleValues',
				viewingEntityIRIParts: 'viewingEntityIRIParts'
			}),
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.districts[this.$route.params.id] !== 'undefined') ? this.districts[this.$route.params.id] : false ;
			},
			routeEntityCountry()
			{
				return this.routeEntity !== false ? this.countries[extractIdFromIri(this.routeEntity.country)] : false;
			},
			mayUpdateRuleset(){
				return (typeof this.$route.params.id !== 'undefined' && this.isGranted(permissions.RULES.UPDATE,this.routeEntity['@id']))
			},
			mayUpdateDisabled(){
				return (typeof this.$route.params.id !== 'undefined' && this.routeEntity.company !== null && this.isGranted(permissions.DISTRICT.DISABLED.UPDATE,this.routeEntity.company))
			},
			routeCountry(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.countries[this.$route.params.id] !== 'undefined') ? this.countries[this.$route.params.id] : false ;
			},
		},
		methods: {
			toggleDisabledInPeriods(period){
				if(this.disabledInPeriods.indexOf(period) === -1)
					this.disabledInPeriods.push(period)
				else
					this.disabledInPeriods.splice(this.disabledInPeriods.indexOf(period),1);
				console.log(this.disabledInPeriods);
			},
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'District'
					},
					method: (this.$route.name === 'district-edit') ? 'put' : 'post',
					payload: {
						name: this.name,
						homeAddress: this.homeAddress,
						allowBookingsFrom: this.allowBookingsFrom,
						country: this.country,
						bounds: JSON.stringify(this.bounds)
					}
				};
				if(this.$route.name === 'district-edit')
					payload.entity.id = this.$route.params.id;

				// @deprecated Rules are no longer managed on district level since version 2
/*				if(this.mayUpdateRuleset)
					payload.payload.ruleset = this.buildRulesetRequest();*/

				if(this.viewingEntity !== false && this.viewingEntityIRIParts.entity === 'Company')
				{
					payload.payload.company = this.viewingEntity
				}
				else if(this.hasGlobalSuperAdmin)
				{
					payload.payload.company = this.company !== '' ? this.company : null
				}

				if(this.mayUpdateDisabled)
					payload.payload.disabledInPeriods = this.disabledInPeriods;

				return payload;
			},
			buildRulesetRequest()
			{
				let ruleset = {};
				Object.keys(this.ruleset).forEach((set) => {
					const setKeys = Object.keys(this.ruleset[set]);
					if(setKeys.length !== 0)
					{
						setKeys.forEach((rule) => {
							ruleset[rule] = this.ruleset[set][rule];
						});
					}
				});
				
				return ruleset;
			},
			attemptSubmit(){
				if(this.loader) return;
				
				this.validateInputs();
				if(this.nameError === '' && this.boundsError === '' && this.countryError === '' && this.homeAddressError === '' && this.allowBookingsFromError === '' && Object.keys(this.bookingRuleErrors).length === 0)
				{
					this.loader = true;
					this.responseError = '';
					this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
					.then(result => {
						if(typeof result.id !== 'undefined')
						{
							this.$store.dispatch('concrete/genericEntityRequest',{entity: {id:result.id,'@type':'District'}})
							.then(() => {
								if(this.$route.name === 'district-add')
								{
									this.$swal.fire({
										icon: 'success',
										title: this.$t("alerts.districtWasCreatedTitle"),
										text: this.$t("alerts.districtWasCreatedDescription"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									}).then((result) => {
										if (result.isConfirmed) {
											this.$router.push({ name: 'entity-list', params: {entityType: 'districts',lang: this.$i18n.locale} })
										}
									});
								}
								else
								{
									this.$swal.fire({
										icon: 'success',
										title: this.$t("alerts.districtWasUpdatedTitle"),
										text: this.$t("alerts.districtWasUpdatedDescription"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									}).then((response) => {
										if (response.isConfirmed) {
											this.$router.push({ name: 'district-view', params: {id: result.id,lang: this.$i18n.locale} })
										}
									});
								}
							},() => {
								if(this.$route.name === 'district-add')
								{
									this.$swal.fire({
										icon: 'success',
										title: this.$t("alerts.districtWasCreatedTitle"),
										text: this.$t("alerts.districtWasCreatedDescription"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									}).then((result) => {
										if (result.isConfirmed) {
											this.$router.push({ name: 'entity-list', params: {entityType: 'districts',lang: this.$i18n.locale} })
										}
									});
								}
								else
								{
									this.$swal.fire({
										icon: 'success',
										title: this.$t("alerts.districtWasUpdatedTitle"),
										text: this.$t("alerts.districtWasUpdatedDescription"),
										confirmButtonText: this.$t("terms.ok"),
										confirmButtonColor: "#495760"
									}).then((response) => {
										if (response.isConfirmed) {
											this.$router.push({ name: 'district-view', params: {id: result.id,lang: this.$i18n.locale} })
										}
									});
								}
							})
							
						}
						else
						{
							//Can this happen?
							console.log(result);
							this.responseError = 'An error occurred. Please try again.';
						}
					})
					.catch(error => {
						console.log(error);
						if(typeof error.data !== 'undefined' && typeof error.data.violations !== 'undefined')
						{
							const violations = error.data.violations;
							let errorText = [];
							violations.forEach(error =>
							{
								errorText.push(this.$t('symfonyErrors.'+error.message));
							});
							
							this.$swal.fire({
								icon: 'error',
								title: this.$t("errors.errorsInFields"),
								html: errorText.join('<br>'),
								confirmButtonText: this.$t("terms.ok"),
								confirmButtonColor: "#495760"
							});
						}
						else
						{
							this.fireGenericError();
						}
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
			},
			validateInputs(){
				this.nameError = '';
				this.homeAddressError = '';
				this.boundsError = '';
				this.countryError = '';
				this.allowBookingsFromError = '';
				this.bookingRuleErrors = {};
				if(this.name === '')
					this.nameError = this.$t("validation.pleaseProvideDistrictName");
				if(this.homeAddress === '')
					this.homeAddressError = this.$t("validation.pleaseProvideHomeAddress");
				if(this.country === '')
					this.countryError = this.$t("validation.pleaseSelectCountry");
				if(this.bounds.length < 3)
					this.boundsError = this.$t("validation.pleaseSetBoundsOfDistrict");
				
				if(this.mayUpdateRuleset)
				{
					this.determineRulesetErrors();
				}
			},
			determineRulesetErrors(){
				if(typeof this.ruleset.booking[rules["BOOKING:SLOTS"].key] !== 'undefined')
				{
					this.ruleset.booking[rules["BOOKING:SLOTS"].key].forEach((single, index) =>
					{
						Object.keys(single).forEach((property) =>
						{
							if (this.ruleset.booking[rules["BOOKING:SLOTS"].key][index][property] === null || this.ruleset.booking[rules["BOOKING:SLOTS"].key][index][property] === '')
							{
								if (typeof this.bookingRuleErrors.bookingslot === 'undefined')
								{
									this.bookingRuleErrors.bookingslot = {};
								}
								this.bookingRuleErrors.bookingslot[property + '-' + index] = this.$t('bookingRulesForm.repeaterError');
							}
						});
					})
				}
				
				if(typeof this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key] !== 'undefined')
				{
					this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key].forEach((single,index) => {
						Object.keys(single).forEach((property) => {
							if(this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key][index][property] === null || this.ruleset.booking[rules["BOOKING:LATE_CANCEL_PENALTIES"].key][index][property] === '')
							{
								if(typeof this.bookingRuleErrors.lateCancel === 'undefined')
								{
									this.bookingRuleErrors.lateCancel = {};
								}
								this.bookingRuleErrors.lateCancel[property+'-'+index] = this.$t('bookingRulesForm.repeaterError');
							}
						});
					})
				}
			},
			updateLocalValues(){
				this.name = this.routeEntity.name;
				this.allowBookingsFrom = this.routeEntity.allowBookingsFrom;
				this.homeAddress = this.routeEntity.homeAddress;
				this.bounds = JSON.parse(this.routeEntity.bounds);
				this.readonlyBounds = [];


				this.country = this.routeEntity.country;
				this.chiefs = this.routeEntity.users;
				if(typeof this.routeEntity.ruleset !== 'undefined')
				{
					this.possibleRules.forEach((rule) => {
						if(typeof this.routeEntity.ruleset[rule] !== 'undefined')
						{
							this.ruleset[rules[rule].group][rule] = this.routeEntity.ruleset[rule];
						}
					})
				}
				this.disabledInPeriods = typeof this.routeEntity.disabledInPeriods !== 'undefined' ? this.routeEntity.disabledInPeriods : [];

				this.ready = true;
			},
			fetchRequired(){
				this.ready = false;
				let promises = [];
				if(typeof this.$route.params.id !== 'undefined')
				{
					promises.push(this.addRequiredApiRequest({type: 'generic',payload: {entity: {'id': this.$route.params.id, '@type': 'District'}}}))
				}
				
				if(promises.length > 0)
				{
					Promise.all(promises)
					.then(response =>
					{
						this.updateLocalValues();
					})
					.catch(error =>
					{
						console.log(error);
						this.ready = true;
					});
				}
				else
				{
					this.ready = true;
				}
			},
			isDistrictBoundsKnown(){
				if(this.routeEntityCountry === false || typeof this.routeEntityCountry.districts === 'undefined' || this.routeEntityCountry.districts.length === 0)
					return true;

				return typeof this.routeEntityCountry.districts[0] !== 'object' || typeof this.routeEntityCountry.districts[0].bounds === 'undefined'
			},
			fetchDistrictCountry(){
				if(this.routeEntity === false)
					return;
				i++;
				const localIteration = i+0;
				this.$store.dispatch('concrete/genericEntityRequest',{parameters: {explode:'district:bounds'},entity: {'id': this.routeEntityCountry.id,'@type':'Country'}})
				.catch(error => {
					console.log(error);
				})
				.then(() => {
					if(localIteration === i)
					{
						this.readonlyBounds = [];
						this.routeEntityCountry.districts.forEach((district)=>{
							
							if(district.id !== parseInt(this.$route.params.id)){
								this.readonlyBounds.push(JSON.parse(district.bounds));
							}
						});
					}
				},() => {
					if(localIteration === i)
					{
						this.readonlyBounds = [];
						this.routeEntityCountry.districts.forEach((district)=>{
							
							if(district.id !== parseInt(this.$route.params.id)){
								this.readonlyBounds.push(JSON.parse(district.bounds));
							}
						});
					}
				})
			}
		},
		created()
		{
			this.fetchRequired();
		},
		mounted()
		{
			this.fetchDistrictCountry();
		},
		watch:{
			$route(){
				this.fetchRequired();
			},
			routeEntity(){
				this.fetchDistrictCountry();
			}
		}
	};
</script>

<style scoped>

</style>
